import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import fbIc from '../img/fb-ic.png'
import twIc from '../img/tw-ic.png'
import instaIc from '../img/insta-ic.png'
import tcIc from '../img/tc-ic.png'
import BackgroundImage from 'gatsby-background-image'
import useInViewPort from '../hooks/useInViewPort'

const GoogleMap = React.lazy(() => import('./GoogleMap'))

export default function Footer() {
	const { nodeRef, isVisible } = useInViewPort(50)
	const data = useStaticQuery(
		graphql`
			query {
				footerback: file(relativePath: { eq: "footer-back.png" }) {
					childImageSharp {
						fluid(quality: 50, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)
	const isSSR = typeof window === 'undefined'
	return (
		<BackgroundImage
			Tag='footer'
			className='footer is-clipped'
			fluid={data.footerback.childImageSharp.fluid}
			style={{
				backgroundSize: 'cover'
			}}
		>
			<a id='contact' />
			<div className='content' ref={nodeRef}>
				{isVisible && (
					<div className='footer_btm'>
						<div className='footer-box'>
							<div className='footer-column'>
								<StaticImage
									src='../img/TrippyWizardLogo.png'
									alt='Logo'
									placeholder='blurred'
									quality={10}
								/>
								<h4>LOCATIONS</h4>
								<p>
									Trippy Wizard Weed Dispensary DC
									<br />
									1755 S St NW Suite A, Washington, DC 20009
									<br />
									(202) 417-7712
								</p>
								<div className='social-icons flex'>
									<a
										href='https://www.facebook.com/TrippyWizardDC'
										target='_blank'
									>
										<img src={fbIc} />
									</a>
									<a
										href='https://www.instagram.com/trippywizarddc/'
										target='_blank'
									>
										<img src={instaIc} />
									</a>
								</div>
							</div>
							<div className='footer-column'>
								<h4>NAVIGATE</h4>
								<ul>
									<li>
										<Link to='/'>Home</Link>
									</li>
									<li>
										<Link to='/collections/cannabis-flower/'>Flower</Link>
									</li>
									<li>
										<Link to='/collections/pre-rolls/'>Pre Rolls</Link>
									</li>
									<li>
										<Link to='/collections/weed-vapes/'>Weed Vapes</Link>
									</li>
									<li>
										<Link to='/collections/edibles/'>Edibles</Link>
									</li>
									<li>
										<Link to='/collections/concentrates/'>Concentrates</Link>
									</li>
									<li>
										<Link to='/collections/magic-mushrooms/'>
											Magic Mushrooms
										</Link>
									</li>
									<li>
										<Link to='/collections/dmt/'>DMT</Link>
									</li>
									<li>
										<Link to='/collections/oz-weed-shroom-deals/'>
											Ounce Deals
										</Link>
									</li>
								</ul>
								<h4>HELP & INFO</h4>
								<ul>
									<li>
										<Link to='/contact'>Contact Us</Link>
									</li>
									<li>
										<Link to='/about-us/'>About Us</Link>
									</li>
									<li>
										<Link to='/blog/'>Blog</Link>
									</li>
									<li>
										<Link to='#'>Privacy Policy</Link>
									</li>
									<li>
										<Link to='/sitemap.xml'>Sitemap</Link>
									</li>
								</ul>
							</div>
							<div className='footer-column'>
								<h4>HOURS</h4>
								<p>
									<br />
									Monday 10AM - 9PM
									<br />
									Tuesday 10AM - 9PM
									<br />
									Wednesday 10AM - 9PM
									<br />
									Thursday 10AM - 9PM
									<br />
									Friday 10AM - 10PM
									<br />
									Saturday 10AM - 10PM
									<br />
									Sunday 10AM - 9PM
								</p>
								<p>
									THE MOST TRUSTED RECREATIONAL
									<br /> DISPENSARY IN WASHINGTON, DC
								</p>

								<div
									className='gMap'
									style={{ height: '300px', width: '100%' }}
								>
									{!isSSR && (
										<React.Suspense fallback={<div />}>
											<GoogleMap />
										</React.Suspense>
									)}
								</div>
							</div>
						</div>

						<section className='section'>
							<p>
								<strong>
									COPYRIGHT © {new Date().getFullYear()} TRIPPY WIZARD, LLC
								</strong>
							</p>{' '}
						</section>
					</div>
				)}
			</div>
		</BackgroundImage>
	)
}
