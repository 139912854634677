import { useEffect, useRef, useState } from 'react'

export default function useInViewPort(THRESHOLD = 0) {
	let nodeRef = useRef(null)
	let observerRef = useRef(null)

	const [isVisible, setIsVisible] = useState(false)

	useEffect(() => {
		observerRef.current = new IntersectionObserver(
			(entries) => {
				setIsVisible(entries[0].isIntersecting)
			},
			{ THRESHOLD }
		)

		observerRef.current.observe(nodeRef.current)

		return () => {
			observerRef.current.disconnect()
		}
	}, [])

	useEffect(() => {
		if (isVisible) {
			observerRef.current.disconnect()
		}
	}, [isVisible])

	return { nodeRef, isVisible }
}
