import React, { useEffect, useState } from 'react'

import { Link, graphql, useStaticQuery } from 'gatsby'

import { StaticImage } from 'gatsby-plugin-image'
import Hamburger from 'hamburger-react'

import useGlobal from '../store'
import { getTotal } from '../utils/getTotal'

import categories from '../config/categories'

import weedOrderNow from '../img/weedOrderNow.svg'
import shoppingCart from '../img/shoppingCart.svg'
import shoppingCartWhite from '../img/shoppingCartWhite.svg'
import downarrow from '../img/down-arrow.svg'
import headerBack from '../img/header-back.png'
import BackgroundImage from 'gatsby-background-image'
import useResponsive from '../hooks/useResponsive'

const CartModal = React.lazy(() => import('./CartModal'))

export default function Header() {
	const { screenWidth } = useResponsive()
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [menu, setMenu] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const data = useStaticQuery(
		graphql`
			query {
				menuback: file(relativePath: { eq: "Menu-Back.png" }) {
					childImageSharp {
						fluid(quality: 90, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)
	function openModal() {
		setModalIsOpen(true)
	}

	function closeModal() {
		setModalIsOpen(false)
	}

	const [globalState, globalActions] = useGlobal()
	const [items, setItems] = useState()
	const [total, setTotal] = useState()

	useEffect(() => {
		if (globalState.cart)
			setItems(globalState.cart.reduce((acc, item) => acc + item.qty, 0))
	}, [globalState])

	useEffect(() => {
		if (globalState.cart) {
			const { total } = getTotal(globalState)
			setTotal(total)
		}
	}, [globalState])

	const isSSR = typeof window === 'undefined'

	return (
		<>
			{!isSSR && (
				<React.Suspense fallback={<div />}>
					<CartModal
						modalIsOpen={modalIsOpen}
						closeModal={closeModal}
						cart={globalState.cart}
						total={total}
						addToCart={globalActions.addToCart}
						removeFromCart={globalActions.removeFromCart}
					/>
				</React.Suspense>
			)}
			{/* Mobile */}
			{screenWidth <= 780 && (
				<>
					<nav
						className='navbar is-fixed-top is-light is-hidden-desktop is-hidden-tablet'
						style={{
							backgroundImage: `url(${headerBack})`,
							backgroundSize: 'cover',
							backgroundColor: '#ffffff'
						}}
					>
						<nav className='level is-mobile'>
							<div className='level-left'>
								<div className='level-item p-3'>
									{!isOpen && <Hamburger toggled={isOpen} toggle={setIsOpen} />}
								</div>
							</div>
							<div className='level-item has-text-centered p-3'>
								<Link to='/'>
									<figure className='image is-32x32'>
										<StaticImage
											alt='trippy logo'
											src='../img/TrippyWizardLogo.png'
											style={{ maxWidth: '90px' }}
											placeholder='blurred'
										/>
									</figure>
								</Link>
							</div>
							<div className='level-right'>
								<div className='level-item'>
									<div className='control'>
										{items > 0 ? (
											<>
												<div
													className='button is-primary mr-3'
													onClick={openModal}
												>
													<figure className='image is-16x16 mr-1'>
														<img alt='' src={shoppingCartWhite} />
													</figure>
													<span className='is-size-7'>
														(${total})
														{/* {items} {items > 1 ? "items" : "item"} (${total}) */}
													</span>
												</div>
											</>
										) : (
											<>
												<Link
													to='/shop'
													className='button is-primary is-small mr-3 o-btn'
												>
													<span className='has-text-white has-text-weight-bold'>
														Order
													</span>
												</Link>
											</>
										)}
									</div>
								</div>
							</div>
						</nav>
					</nav>

					<div className={!isOpen ? 'modal' : 'modal is-active'}>
						<div className='modal-background'></div>
						<div className='modal-content h-menu'>
							<BackgroundImage
								className='box'
								fluid={data.menuback.childImageSharp.fluid}
								style={{
									zIndex: 10000,
									overflow: 'hidden',
									// backgroundImage: `url(${menuBack})`,
									backgroundSize: 'cover'
								}}
							>
								<button
									type='button'
									onClick={() => setIsOpen(false)}
									className='delete is-pulled-right has-background-black'
									aria-label='close'
								/>
								<br />
								<figure className='image'>
									<StaticImage
										alt=''
										src='../img/TrippyWizardLogo.png'
										style={{ height: '96px' }}
										placeholder='blurred'
										imgStyle={{ maxWidth: '160px', margin: '0 auto' }}
									/>
								</figure>
								<br />
								<div className='container has-text-centered mobileMenu'>
									{items > 0 ? (
										<>
											<Link
												to='/checkout'
												className='button is-primary mr-3 is-medium'
												onClick={openModal}
											>
												<figure className='image is-16x16 mr-1'>
													<img alt='' src={shoppingCart} />
												</figure>
												<span className='is-size-7'>
													{items} {items > 1 ? 'items' : 'item'} (${total})
												</span>
											</Link>
											<br />
											<br />
										</>
									) : (
										<>
											<Link
												to='/shop'
												onClick={() => setIsOpen(false)}
												className='button has-text-weight-semibold is-primary is-fullwidth is-medium has-background-white has-text-blue'
											>
												<span>Shop All</span>
											</Link>
										</>
									)}
									<div className='menu-in' style={{ overflowY: 'scroll' }}>
										{categories.map((category) => (
											<div key={category.name} className='navbar-item'>
												<Link
													onClick={() => setIsOpen(false)}
													to={`/collections/${category.handle}`}
													className='is-size-6 has-text-weight-semibold has-text-blue has-background-white'
												>
													{category.name}
												</Link>
											</div>
										))}
									</div>
									<div className='tab'>
										<input id='tab-31' type='checkbox' />
										<label
											className='button has-text-weight-semibold is-primary is-fullwidth is-medium has-background-white has-text-blue mdl'
											htmlFor='tab-31'
										>
											ABOUT <img src={downarrow} />
										</label>
										<div className='content'>
											<div className='drop-down-menu mobile-drop-down'>
												<Link
													onClick={() => setIsOpen(false)}
													to='/washington-dc-dispensary-location/'
													className='navbar-item is-size-6 has-text-blue has-text-weight-semibold'
												>
													Dispensary Location
												</Link>
												<input id='tab-32' type='checkbox' />
												<label
													className='button is-size-6 has-text-weight-semibold is-primary is-fullwidth is-medium has-background-white has-text-blue mdl'
													htmlFor='tab-2'
												>
													Delivery <img src={downarrow} />
												</label>
												<div className='content'>
													<div className='drop-down-menu'>
														<Link
															onClick={() => setIsOpen(false)}
															to='/delivery/annandale-va/'
															className='navbar-item is-size-6 has-text-blue has-text-weight-semibold'
														>
															Annandale VA
														</Link>
														<Link
															onClick={() => setIsOpen(false)}
															to='/delivery/springfield-va/'
															className='navbar-item is-size-6 has-text-blue has-text-weight-semibold'
														>
															Springfield VA
														</Link>
														<Link
															onClick={() => setIsOpen(false)}
															to='/delivery/falls-church-va/'
															className='navbar-item is-size-6 has-text-blue has-text-weight-semibold'
														>
															Falls Church VA
														</Link>
														<Link
															onClick={() => setIsOpen(false)}
															to='/delivery/mclean-va/'
															className='navbar-item is-size-6 has-text-blue has-text-weight-semibold'
														>
															Mclean VA
														</Link>
														<Link
															onClick={() => setIsOpen(false)}
															to='/delivery/alexandria-va/'
															className='navbar-item is-size-6 has-text-blue has-text-weight-semibold'
														>
															Alexandria VA
														</Link>
														<Link
															onClick={() => setIsOpen(false)}
															to='/delivery/arlington-va/'
															className='navbar-item is-size-6 has-text-blue has-text-weight-semibold'
														>
															Arlington VA
														</Link>
														<Link
															onClick={() => setIsOpen(false)}
															to='/delivery/Tysons-va/'
															className='navbar-item is-size-6 has-text-blue has-text-weight-semibold'
														>
															Tysons VA
														</Link>
														<Link
															onClick={() => setIsOpen(false)}
															to='/delivery/fairfax-va/'
															className='navbar-item is-size-6 has-text-blue has-text-weight-semibold'
														>
															Fairfax VA
														</Link>
														<Link
															onClick={() => setIsOpen(false)}
															to='/delivery/potomac-md/'
															className='navbar-item is-size-6 has-text-blue has-text-weight-semibold'
														>
															Potomac MD
														</Link>
														<Link
															onClick={() => setIsOpen(false)}
															to='/delivery/silver-spring-md/'
															className='navbar-item is-size-6 has-text-blue has-text-weight-semibold'
														>
															Silver Spring MD
														</Link>
														<Link
															onClick={() => setIsOpen(false)}
															to='/delivery/chevy-chase-md/'
															className='navbar-item is-size-6 has-text-blue has-text-weight-semibold'
														>
															Chevy Chase MD
														</Link>
														<Link
															onClick={() => setIsOpen(false)}
															to='/delivery/bethesda-md/'
															className='navbar-item is-size-6 has-text-blue has-text-weight-semibold'
														>
															Bethesda MD
														</Link>
														<Link
															onClick={() => setIsOpen(false)}
															to='/delivery/hyattsville-md/'
															className='navbar-item is-size-6 has-text-blue has-text-weight-semibold'
														>
															Hyattsville MD
														</Link>
														<Link
															onClick={() => setIsOpen(false)}
															to='/delivery/cheverly-md/'
															className='navbar-item is-size-6 has-text-blue has-text-weight-semibold'
														>
															Cheverly MD
														</Link>
														<Link
															onClick={() => setIsOpen(false)}
															to='/delivery/takoma-park-md/'
															className='navbar-item is-size-6 has-text-blue has-text-weight-semibold'
														>
															Takoma Park MD
														</Link>
														<Link
															onClick={() => setIsOpen(false)}
															to='/delivery/oxon-hill-md/'
															className='navbar-item is-size-6 has-text-blue has-text-weight-semibold'
														>
															Oxon Hill MD
														</Link>
													</div>
												</div>
												<Link
													onClick={() => setIsOpen(false)}
													to='/about-us/'
													className='navbar-item is-size-6 has-text-blue has-text-weight-semibold'
												>
													ABOUT & FAQ
												</Link>
											</div>
										</div>
									</div>
								</div>
							</BackgroundImage>
						</div>
					</div>
				</>
			)}

			{/* Top Desktop*/}
			{screenWidth > 780 && (
				<nav
					className='navbar is-fixed-top is-light is-hidden-mobile'
					style={{
						backgroundImage: `url(${headerBack})`,
						backgroundSize: 'cover',
						backgroundColor: '#ffffff'
					}}
				>
					<div className='container'>
						<div className='navbar-brand'>
							<Link className='navbar-item' to='/'>
								<StaticImage
									alt=''
									src='../img/TrippyWizardLogo.png'
									style={{ maxWidth: '160px' }}
									placeholder='blurred'
								/>
							</Link>
							<div
								className='navbar-burger'
								data-target='navMenu'
								onClick={() => setMenu(!menu)}
							>
								<span></span>
								<span></span>
								<span></span>
							</div>
						</div>

						<div
							id='navMenu'
							className={`navbar-menu ${menu ? 'is-active' : ''}`}
						>
							<div className='navbar-start'>
								<Link
									to='/'
									className='navbar-item is-size-5 has-text-black has-text-weight-bold'
								>
									HOME
								</Link>
								<Link
									to='/shop/'
									className='navbar-item is-size-5 has-text-black has-text-weight-bold'
								>
									SHOP
								</Link>
								<Link
									to='/'
									className='navbar-item is-size-5 has-text-black has-text-weight-bold dropdown-link'
								>
									ABOUT
									<img src={downarrow} />
								</Link>
								<div className='drop-down-menu'>
									<Link
										to='/washington-dc-dispensary-location/'
										className='navbar-item is-size-6 has-text-black has-text-weight-bold'
									>
										Dispensary Location
									</Link>
									<Link
										to='/'
										className='navbar-item is-size-6 has-text-black has-text-weight-bold dropdown-link dd-link'
									>
										Delivery
										<img src={downarrow} />
									</Link>
									<div className='drop-down-menu dd-in'>
										<div className='drop-down-menu-in'>
											<Link
												to='/delivery/annandale-va/'
												className='navbar-item is-size-6 has-text-black has-text-weight-semibold'
											>
												Annandale VA
											</Link>
											<Link
												to='/delivery/springfield-va/'
												className='navbar-item is-size-6 has-text-black has-text-weight-semibold'
											>
												Springfield VA
											</Link>
											<Link
												to='/delivery/falls-church-va/'
												className='navbar-item is-size-6 has-text-black has-text-weight-semibold'
											>
												Falls Church VA
											</Link>
											<Link
												to='/delivery/mclean-va/'
												className='navbar-item is-size-6 has-text-black has-text-weight-semibold'
											>
												Mclean VA
											</Link>
											<Link
												to='/delivery/alexandria-va/'
												className='navbar-item is-size-6 has-text-black has-text-weight-semibold'
											>
												Alexandria VA
											</Link>
											<Link
												to='/delivery/arlington-va/'
												className='navbar-item is-size-6 has-text-black has-text-weight-semibold'
											>
												Arlington VA
											</Link>
											<Link
												to='/delivery/Tysons-va/'
												className='navbar-item is-size-6 has-text-black has-text-weight-semibold'
											>
												Tysons VA
											</Link>
											<Link
												to='/delivery/fairfax-va/'
												className='navbar-item is-size-6 has-text-black has-text-weight-semibold'
											>
												Fairfax VA
											</Link>
										</div>
										<div className='drop-down-menu-in'>
											<Link
												to='/delivery/potomac-md/'
												className='navbar-item is-size-6 has-text-black has-text-weight-semibold'
											>
												Potomac MD
											</Link>
											<Link
												to='/delivery/silver-spring-md/'
												className='navbar-item is-size-6 has-text-black has-text-weight-semibold'
											>
												Silver Spring MD
											</Link>
											<Link
												to='/delivery/chevy-chase-md/'
												className='navbar-item is-size-6 has-text-black has-text-weight-semibold'
											>
												Chevy Chase MD
											</Link>
											<Link
												to='/delivery/bethesda-md/'
												className='navbar-item is-size-6 has-text-black has-text-weight-semibold'
											>
												Bethesda MD
											</Link>
											<Link
												to='/delivery/hyattsville-md/'
												className='navbar-item is-size-6 has-text-black has-text-weight-semibold'
											>
												Hyattsville MD
											</Link>
											<Link
												to='/delivery/cheverly-md/'
												className='navbar-item is-size-6 has-text-black has-text-weight-semibold'
											>
												Cheverly MD
											</Link>
											<Link
												to='/delivery/takoma-park-md/'
												className='navbar-item is-size-6 has-text-black has-text-weight-semibold'
											>
												Takoma Park MD
											</Link>
											<Link
												to='/delivery/oxon-hill-md/'
												className='navbar-item is-size-6 has-text-black has-text-weight-semibold'
											>
												Oxon Hill MD
											</Link>
										</div>
									</div>
									<Link
										to='/about-us/'
										className='navbar-item is-size-6 has-text-black has-text-weight-bold'
									>
										ABOUT & FAQ
									</Link>
								</div>

								<Link
									to='/blog/'
									className='navbar-item is-size-5 has-text-black has-text-weight-bold'
								>
									BLOG
								</Link>
								<Link
									to='#contact'
									className='navbar-item is-size-5 has-text-black has-text-weight-bold'
								>
									CONTACT
								</Link>
							</div>

							<div className='number-box flex'>
								<a
									href='tel:(202) 417-7712'
									className='navbar-item is-size-5 has-text-black has-text-weight-bold'
								>
									(202) 417-7712
								</a>
							</div>

							<div className='navbar-end'>
								<div className='navbar-item'>
									<div className='field is-grouped'>
										{items > 0 ? (
											<>
												<p className='control navBtns'>
													<Link to='/checkout' className='button is-primary'>
														<img
															alt=''
															src={shoppingCart}
															style={{ margin: 10 }}
														/>
														<span>
															{items} items (${total})
														</span>
													</Link>
												</p>
												<p className='control navBtns'>
													<Link to='/shop' className='button is-primary'>
														<span className='has-text-white has-text-weight-bold'>
															Shop Now
														</span>
														<img
															alt=''
															src={weedOrderNow}
															style={{ margin: 10 }}
														/>
													</Link>
												</p>
											</>
										) : (
											<>
												<p className='control navBtns'>
													<Link to='/shop' className='button is-primary'>
														<span className='has-text-white has-text-weight-bold'>
															Shop Now
														</span>
													</Link>
												</p>
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</nav>
			)}
		</>
	)
}
