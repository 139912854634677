export const addToCart = (store, product) => {
	const exists = store.state.cart.find(
		({ shopifyId }) => shopifyId === product.shopifyId
	)

	if (!exists) {
		const cart = store.state.cart.concat([{ ...product, qty: 1 }])
		store.setState({ cart })
	} else {
		const cart = store.state.cart.filter(
			({ shopifyId }) => shopifyId !== product.shopifyId
		)
		const newCart = cart.concat([{ ...product, qty: ++exists.qty }])
		store.setState({ cart: newCart })
	}
}

export const removeFromCart = (store, product) => {
	const exists = store.state.cart.find(
		({ shopifyId }) => shopifyId === product.shopifyId
	)

	if (exists)
		if (exists.qty > 1) {
			const cart = store.state.cart.filter(
				({ shopifyId }) => shopifyId !== product.shopifyId
			)
			const newCart = cart.concat([{ ...product, qty: --exists.qty }])
			store.setState({ cart: newCart })
		} else {
			const cart = store.state.cart.filter(
				({ shopifyId }) => shopifyId !== product.shopifyId
			)

			store.setState({ cart })
		}
}

export const resetCart = (store) => store.setState({ cart: [] })

export const addArt = (store, item) => store.setState({ art: item })
