import '@fontsource/poppins'
import '@fontsource/poppins/300.css'
import '@fontsource/poppins/700.css'
import '@fontsource/lexend/700.css'

export const onInitialClientRender = () => {
	// wait to init GTM until after React has hydrated in this lifecycle
	const dataLayer = window.dataLayer || []
	dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
}
