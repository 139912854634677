import { useState, useEffect } from 'react'

// Custom Hook
const useResponsive = () => {
	const isSSR = typeof window === 'undefined'
	const [screenSize, setScreenSize] = useState({
		screenWidth: isSSR ? 0 : window.innerWidth,
		screenHeight: isSSR ? 0 : window.innerHeight
	})

	useEffect(() => {
		if (isSSR) return
		const handleResize = () => {
			setScreenSize({
				screenWidth: window.innerWidth,
				screenHeight: window.innerHeight
			})
		}

		window.addEventListener('resize', handleResize)

		handleResize()

		return () => window.removeEventListener('resize', handleResize)
	}, [isSSR])

	return screenSize
}

export default useResponsive
