export default [
  {
    name: "Exclusive Designer Weed",
    handle: "exclusive-designer-weed",
    subcategories: [],
  },
  {
    name: "Cannabis Flower",
    handle: "cannabis-flower",
    subcategories: [
      {
        name: "Signature Flower",
        handle: "signature-flower",
        discounts: [],
      },
      {
        name: "Designer Collabs",
        handle: "designer-collabs",
        discounts: [],
      },
      {
        name: "Premium Flower",
        handle: "premium-flower",
        discounts: [
        ],
      },

      {
        name: "Middle Tier Flower",
        handle: "middle-tier-flower",
        discounts: [],
      },
      {
        name: "Organic Flower",
        handle: "organic-flower",
        discounts: [],
      },
    ],
  },
  { name: "Pre Rolls", handle: "pre-rolls", subcategories: [] },
  { name: "Weed Vapes", handle: "weed-vapes", subcategories: [] },
  {
    name: "Edibles",
    handle: "edibles",
    subcategories: [
      // { name: "Sour Pack", handle: "sour-pack", discounts: [] },
      // { name: "Puff Edibles", handle: "puff-edibles", discounts: [] },
    ],
  },

  {
    name: "Concentrates",
    handle: "concentrates",
    subcategories: [
      // { name: "Concentrates", handle: "concentrates", discounts: [] },
    ],
  },
  {
    name: "Magic Mushrooms",
    handle: "magic-mushrooms",
    subcategories: [
      // { name: "Concentrates", handle: "concentrates", discounts: [] },
    ],
  },
  {
    name: "Ounce Deals",
    handle: "oz-weed-shroom-deals",
    subcategories: [],
  },
  {
    name: "DMT",
    handle: "dmt",
    subcategories: [],
  },
  {
    name: "Accessories",
    handle: "accessories",
    subcategories: [],
  },
];
