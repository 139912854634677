import categories from "../config/categories";

export const getTotal = ({ cart }) => {
  const subtotal = cart.reduce(
    (acc, item) => acc + Number(item.variants[0].price) * item.qty,
    0
  );

  const discount = categories
    .flatMap(category =>
      category.subcategories.filter(({ discounts }) => discounts.length > 0)
    )
    .reduce((acc, collection) => {
      const qty = cart.reduce((acc, item) => {
        if (item.collectionHandle === collection.handle) return acc + item.qty;
      }, 0);

      const availableDiscounts = collection.discounts.filter(
        discount => discount.qty <= qty
      );

      const collectionDiscount = availableDiscounts
        .sort((a, b) =>
          a.discount > b.discount ? 1 : b.discount > a.discount ? -1 : 0
        )
        .pop();

      return collectionDiscount ? acc + collectionDiscount.discount : acc;
    }, 0);

  const total = subtotal - discount;

  return { total, subtotal, discount };
};
